import {
  ForwardRefRenderFunction,
  MouseEventHandler,
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Calendar, DateObject } from 'src/ui/components/molecules/Calendar'
import styles from './DesktopDatePicker.module.scss'
import dayStyles from './Day.module.scss'
import weekStyles from './Week.module.scss'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { classNames } from 'src/ui/utils/classnames'
import { Loader } from 'src/ui/components'
import { weekStartDayIndex } from '../weekStartDayIndex'
import { locales } from '../locales'
import { useDatePicker } from '../DatePickerContext'
import { mapDay } from '../mapDay'
import { Time } from 'src/core/Shared/infrastructure/Time'
interface Props {
  renderNav: (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => ReactElement
  onDatesChange: (dates: DateObject[]) => void
  footer: ReactElement | ReactElement[]
}

const DesktopDatePickerWithRef: ForwardRefRenderFunction<
  {
    set?: (key: string, value: number) => void
  },
  Props
> = ({ onDatesChange, renderNav, footer }, ref) => {
  const {
    isLoading,
    checkIn,
    checkOut,
    locale,
    minDate,
    maxDate,
    range,
    changeVisibleMonths,
    getDayVariant,
  } = useDatePicker()
  const [daySelected, setDaySelected] = useState<Date | undefined>()
  const [isNextDay, setIsNextDay] = useState<boolean | undefined>(false)
  const [isPreviousDay, setIsPreviousDay] = useState<boolean | undefined>(false)
  const calendarRef = useRef<{
    set?: (key: string, value: number) => void
  }>()

  useImperativeHandle(
    ref,
    () => {
      return {
        set: isDefined(calendarRef.current)
          ? calendarRef.current.set
          : undefined,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendarRef.current],
  )

  const handleSelectDay = (date: Date) => {
    setDaySelected(date)
    setIsNextDay(false)
    setIsPreviousDay(false)
    return
  }

  const handleRangeOver = (date: Date) => {
    if (isDefined(daySelected) && isUndefined(checkOut)) {
      if (date > daySelected) {
        setIsNextDay(true)
        setIsPreviousDay(false)
        return
      }

      if (date < daySelected) {
        setIsNextDay(false)
        setIsPreviousDay(true)
        return
      }

      setIsNextDay(false)
      setIsPreviousDay(false)
      return
    }
  }

  const setCurrentMonth = useCallback(
    (month: Date) => {
      changeVisibleMonths(
        Time.fromDate(month).startOf('month'),
        Time.fromDate(month).add(1, 'month').endOf('month'),
      )
    },
    [changeVisibleMonths],
  )

  useEffect(
    () => setCurrentMonth(checkIn!),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <Loader variant="gradient" />
        </div>
      )}
      <Calendar
        ref={calendarRef}
        className={classNames(
          checkIn && checkOut && dayStyles.selectedDays,
          isNextDay && dayStyles.nextDay,
          isPreviousDay && dayStyles.previousDay,
          dayStyles.day,
          weekStyles.week,
          styles.calendar,
        )}
        disableMonthPicker
        disableYearPicker
        shadow={false}
        monthYearSeparator=" "
        highlightToday={false}
        numberOfMonths={2}
        maxDate={maxDate}
        minDate={minDate}
        range
        rangeHover
        value={range}
        locale={locales(locale)}
        weekStartDayIndex={weekStartDayIndex(locale)}
        mapDays={({ date }: { date: DateObject }) => {
          const dayVariant = getDayVariant(date.toDate())
          return mapDay(date, dayVariant, {
            onClick: () => {
              handleSelectDay(date.toDate())
            },
            onMouseOver: () => {
              handleRangeOver(date.toDate())
            },
          })
        }}
        onChange={onDatesChange}
        renderButton={renderNav}
        plugins={[footer]}
        onMonthChange={date => setCurrentMonth(date.toDate())}
      />
    </div>
  )
}

export const DesktopDatePicker = forwardRef(DesktopDatePickerWithRef)
