import { ViewingAccelerator } from 'src/core/Shared/domain/Reservation.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export type Variant = 'none' | 'lastRoom' | 'fewRooms' | 'viewingNow'

interface AcceleratorHook {
  variant: Variant
  quantity?: number
}

export const useAccelerators = (
  roomId: string,
  inventory: number,
  viewingAccelerators?: ViewingAccelerator[],
): AcceleratorHook => {
  if (inventory === 1) {
    return {
      variant: 'lastRoom',
      quantity: inventory,
    }
  }

  if (inventory > 1 && inventory <= 3) {
    return {
      variant: 'fewRooms',
      quantity: inventory,
    }
  }

  if (inventory > 3 && isDefined(viewingAccelerators)) {
    const roomViewingAccelerator = viewingAccelerators.find(
      accelerator => accelerator.roomTypeId === roomId,
    )

    if (isDefined(roomViewingAccelerator) && roomViewingAccelerator.count > 0) {
      return {
        variant: 'viewingNow',
        quantity: roomViewingAccelerator.count,
      }
    }
  }

  return {
    variant: 'none',
    quantity: inventory,
  }
}
