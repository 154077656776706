import { Button, Modal, Text } from 'src/ui/components'
import styles from './DatesModal.module.scss'
import { FormProvider } from 'react-hook-form'
import { useDatesRangeForm } from 'src/ui/hooks/useDatesRangeForm'
import { FormEvent, useEffect } from 'react'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { useTrans } from 'src/ui/hooks/useTrans'
import { DatePickerForm } from 'src/ui/views/_components/DatesModal/DatePickerForm'
import { Media } from 'src/ui/styles/objects/Media'
import { createModal } from 'src/ui/hooks/useModal'

interface Props {
  checkIn: Date
  checkOut: Date
  onClose: () => void
  onSubmit: (dates: CheckInCheckOut) => Promise<void>
  initialError?: boolean
}

export const DatesModal = createModal(
  ({ checkIn, checkOut, onClose, onSubmit, initialError = false }: Props) => {
    const { trans } = useTrans(['new-reservation'])
    const {
      methods: datesMethods,
      validateFields,
      setManualErrorMessage,
    } = useDatesRangeForm({
      checkIn,
      checkOut,
    })

    useEffect(() => {
      if (!initialError) {
        return
      }

      setManualErrorMessage(trans('dates-modal_initial-error'))
    }, [initialError])

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault()

      validateFields(onSubmit, onClose)
    }

    return (
      <Modal
        size="xl"
        mobileStyle="fullscreen"
        type="transactional"
        data-testid="dates-modal"
        onClose={onClose}
      >
        <div className={styles.container}>
          <FormProvider {...datesMethods}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <Media mobile tablet>
                <Text fontStyle="l-700" color="dark" className="mb-l">
                  {trans('occupancy-form_dates_title')}
                </Text>
              </Media>
              <DatePickerForm />
            </form>
          </FormProvider>
          <Media mobile tablet>
            <div className={styles.buttonWrapper}>
              <Button onClick={handleSubmit}>
                {trans('dates-modal_submit')}
              </Button>
            </div>
          </Media>
        </div>
      </Modal>
    )
  },
)
