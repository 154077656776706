import {
  Carousel,
  Divider,
  IconText,
  Markdown,
  ModalV2,
} from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { Text } from 'src/ui/components/atoms/Text'
import { Image } from 'src/ui/components/atoms/Image'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import {
  getRoomImageLoader,
  getRoomServices,
  getServiceImageLoader,
  getRoomI18nImages,
} from 'src/ui/utils/images'
import styles from './RoomDetailsModal.module.scss'
import { Media } from 'src/ui/styles/objects/Media'
import { useTrans } from 'src/ui/hooks/useTrans'
import { AvailabilityRoom } from 'src/core/Availability/domain/Availability.model'
import { getRoomInfoFromHotel, Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { RoomIcon } from 'src/ui/components/atoms/Icon/_icons/specs/RoomIcon'
import { PeopleIcon } from 'src/ui/components/atoms/Icon/_icons/specs/PeopleIcon'
import { ReservationRoomStay } from 'src/core/Shared/domain/Reservation.model'

interface Props {
  room: AvailabilityRoom | ReservationRoomStay['room']
  hotel: Hotel
}

export const RoomDetailsModal = createModal(({ room, hotel }: Props) => {
  const { trans } = useTrans(['new-reservation'])
  const roomInfo = getRoomInfoFromHotel(room, hotel)
  const services = getRoomServices(room, hotel)
  const hasServices = services.length > 0
  const showGuestBreakdown = roomInfo.guests > 1 && roomInfo.maxChildren > 0
  const showMaxGuests = roomInfo.guests > 0

  return (
    <ModalV2
      size="xl"
      data-quantum-modal="RoomDetailsModal"
      title={room.name}
      withButtons={false}
    >
      <section className={styles.contentContainer}>
        <div className={styles.firstColumn}>
          <div className={styles.carouselContainer}>
            <Carousel
              imageSlides={getRoomI18nImages(trans)(room, hotel)}
              loader={getRoomImageLoader({
                aspectRatio: { width: 16, height: 9 },
              })}
              alt={trans('available-rooms_carousel_alt', {
                roomName: room.name,
              })}
            />
          </div>
          <Text fontStyle="m-700" className="mb-xs" as="p">
            {roomInfo.description}
          </Text>
          <div className="mb-l">
            <Markdown content={roomInfo.detailedDescription} />
          </div>
        </div>
        <div className={styles.secondColumn}>
          <Divider dir="horizontal" className={styles.divider} />
          <div className={styles.roomInfo}>
            <Media laptop desktop>
              <Text fontStyle="m-700" color="dark" as="h3">
                {trans('room-details-modal_room-info-title')}
              </Text>
            </Media>
            {showMaxGuests && (
              <Flex gap="xs">
                <Icon icon={PeopleIcon} size="l" />
                <div>
                  <Text fontStyle="m-300" color="dark" as="p">
                    {trans('details-room_max-guests-number', {
                      guests: roomInfo.guests,
                    })}
                  </Text>
                  {showGuestBreakdown && !hotel.isAdultsOnly && (
                    <Text fontStyle="s-300" color="dark" as="p">
                      {trans('details-room_max-adult-children-number', {
                        adults: roomInfo.maxAdults,
                        children: roomInfo.maxChildren,
                      })}
                    </Text>
                  )}
                </div>
              </Flex>
            )}
            {isDefined(roomInfo.size) && (
              <>
                <Media mobile tablet>
                  <Text fontStyle="m-300" color="dark" as="p">
                    {trans('available-rooms_size', {
                      size: roomInfo.size.value,
                    })}
                  </Text>
                </Media>
                <Media laptop desktop>
                  <IconText
                    icon={RoomIcon}
                    iconSize="l"
                    iconColor="icon-dark"
                    text={trans('available-rooms_size', {
                      size: roomInfo.size.value,
                    })}
                    fontStyle="m-300"
                    fontColor="dark"
                    gap="xs"
                  />
                </Media>
              </>
            )}
          </div>
          {hasServices && (
            <>
              <Divider dir="horizontal" className={styles.divider} />
              <Services services={services} />
            </>
          )}
        </div>
      </section>
    </ModalV2>
  )
})

const Services = ({
  services,
}: {
  services: ReturnType<typeof getRoomServices>
}) => {
  const { trans } = useTrans(['new-reservation'])
  return (
    <>
      <Text fontStyle="m-700" color="dark" as="h3" className="mb-m">
        {trans('room-details-modal_services-title')}
      </Text>
      {services.map(service => (
        <Flex key={service.id} gap="xs" className="mb-m">
          <Image
            aria-hidden
            alt={service.icon.alt}
            src={service.icon.src}
            width={24}
            height={24}
            quality={75}
            loader={getServiceImageLoader}
            className={styles.serviceImage}
          />
          <Text fontStyle="m-300" color="dark">
            {service.text}
          </Text>
        </Flex>
      ))}
    </>
  )
}
