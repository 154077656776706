import { Divider, ModalV2, Text } from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import { PoliciesDetails } from 'src/core/Shared/domain/Policies.model'
import { Flex } from 'src/ui/styles/objects/Flex'
import { getDetails as getGuaranteeDetails } from 'src/ui/views/_components/GuaranteePolicy/GuaranteePolicy.model'
import { getGuaranteeTitle } from 'src/ui/i18n/getGuaranteeTitle'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'
import { getCancellationTitle } from 'src/ui/i18n/getCancellationTitle'
import { getBody } from 'src/ui/views/_components/CancellationPolicy/CancellationPolicy.model'

interface Props {
  policiesDetails: PoliciesDetails
  hasToShowPrices: boolean
  hasIncludedExtras?: boolean
}

export const RatePoliciesModal = createModal(
  ({ policiesDetails, hasToShowPrices, hasIncludedExtras }: Props) => {
    const { trans } = useTrans(['new-reservation', 'common'])
    const { formatPrice } = usePriceFormatter()

    const cancellation = policiesDetails.cancellation
    const guarantee = policiesDetails.guarantee

    const guaranteeTitle = getGuaranteeTitle(
      guarantee,
      'before-reservation',
      trans,
    )
    const guaranteeDetails = getGuaranteeDetails(
      guarantee,
      'before-reservation',
      trans,
      formatPrice,
      hasToShowPrices,
      hasIncludedExtras,
    )

    const cancellationTitle = getCancellationTitle(
      cancellation,
      trans,
      guarantee,
      true,
    )
    const cancellationBody = getBody(
      cancellation,
      trans,
      formatPrice,
      hasToShowPrices,
      guarantee,
    )

    const isHighlighted =
      cancellation.cancellationType === 'free' &&
      guarantee.type.key !== 'partial-percentage'

    return (
      <ModalV2
        withButtons={false}
        size="xl"
        title={trans('new-reservation:rate-policies-modal_title')}
      >
        <Flex direction="column">
          <Text
            as="h1"
            fontStyle={{ mobile: 'm-700', tablet: 'l-700' }}
            className="mt-xs mb-xs mr-m ml-l"
          >
            {guaranteeTitle}
          </Text>

          <Divider dir="horizontal" className="mb-xs" />

          <ul className="mt-xs mb-xs mr-m ml-m">
            {guaranteeDetails.map((detail, index) => (
              <li key={index}>
                <Text fontStyle="s-300"> • {detail}</Text>
              </li>
            ))}
          </ul>
        </Flex>

        <div className="mt-xl mb-3xl">
          <Text
            color={isHighlighted ? 'support-success' : 'dark'}
            as="h2"
            className="mt-xs mb-xs mr-m ml-l"
            fontStyle={{ mobile: 'm-700', tablet: 'l-700' }}
          >
            {cancellationTitle}
          </Text>

          <Divider dir="horizontal" className="mt-xs mb-xs" />

          <div className="mr-m ml-m mt-xs mb-xs">
            <Text as="p" fontStyle="s-300">
              {cancellationBody}
            </Text>
          </div>
        </div>
      </ModalV2>
    )
  },
)
